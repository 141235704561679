import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLocation } from "react-router-dom"; // Assuming you are using React Router
import logger from "../../common/logging/AILogger";
import { CustomProperties } from "../../common/logging/logger";
import {
  CommonLoggingConstants,
  ErrorCodes,
  EventCodes,
  LogSeverityLevel,
} from "../../config/loggingCodes";
import { options } from "./BotOptions";
import ErrorComponent from "./BotError";
import jwt_decode from "jwt-decode";
import { httpServiceWithToken } from "../../config/apiInterceptor";
import apiConfig from "../../config/apiConfig";
import moment from "moment";
import styled from "./VirtualHost.styled";
import { Spinner } from "office-ui-fabric-react";

// Declare the global variable WebChat to inform TypeScript
declare global {
  interface Window {
    WebChat: any;
  }
}

interface DecodedToken {
  exp: number;
  iat: number;
  [key: string]: any;
}

const decodeJWT = (token: string): DecodedToken | null => {
  try {
    return jwt_decode<DecodedToken>(token);
  } catch (error) {
    logger.logError(
      ErrorCodes.ER_120,
      `${CommonLoggingConstants.ComponentName}VirtualHost:API`,
      JSON.stringify((error as Error).stack)
    );
    return null;
  }
};

const BotChatComponent = () => {
  const customProperties: CustomProperties = [];
  const webChatRef = useRef();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [loading, setLoading] = useState<boolean>(true); // State to handle loading
  const [token, setToken] = useState(params.get("token") || ""); // Retrieve the 'token' query parameter
  const [visitInfo, setVisitInfo] = useState<any>(null); // State to hold the API response data
  const [error, setError] = useState<string | null>(null); // State to hold error messages

  const fetchVisitInfo = useCallback((customProperties: CustomProperties) => {
    httpServiceWithToken(token)
      .get(apiConfig.GetVisitInfoVirtualHost())
      .then((response) => {
        setVisitInfo(response.data);
        setError(null); // Clear any previous error

        logger.logEvent(
          EventCodes.EV_108,
          `${CommonLoggingConstants.ComponentName}VirtualHost:VisitAPI`,
          customProperties
        );
      })
      .catch((error) => {
        logger.logError(
          ErrorCodes.ER_121,
          "",
          `${CommonLoggingConstants.ComponentName}VirtualHost:VisitAPI`,
          JSON.stringify((error as Error).stack),
          LogSeverityLevel.Error,
          customProperties
        );
        setError("Failed to fetch visit information."); // Set error message
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]); // Add token to the dependency array

  useEffect(() => {
    if (token) {
      let decodedToken = decodeJWT(token);
      customProperties["VisitId"] = decodedToken?.id;
      customProperties["Info"] = decodedToken?.username;
      fetchVisitInfo(customProperties);
      const webChatOptions = {
        directLine: window.WebChat.createDirectLine({
          token: token,
        }),
        userID: decodedToken?.id,
        username: "Web Chat User",
        locale: "en-US",
        styleOptions: options,
      };
      webChatRef.current = window.WebChat.renderWebChat(
        webChatOptions,
        document.getElementById("webchat")
      );

      return () => {
        if (webChatRef.current) {
          // Dispose of Web Chat component
        }
      };
    }
  }, [token]);

  if (!token) {
    return <ErrorComponent responseData={"Token Not present"} />;
  }

  if (error) {
    return <ErrorComponent responseData={error} />;
  }

  return (
    <div>
      <div style={styled.container}>
        {loading ? (
          <Spinner
            styles={{
              root: {
                position: "fixed",
                top: "50%",
                left: "50%",
              },
            }}
          />
        ) : (
          <div style={styled.header}>
            <div style={styled.hostName}>
              Chatting with <span>{visitInfo?.hostName}</span>
            </div>
            <div style={styled.meetingTime}>
              {moment(visitInfo?.scheduledStartTime).format("DD/MM") +
                " " +
                moment(visitInfo?.scheduledStartTime).format("LT")}
            </div>
            <div style={styled.meetingSubject}>
              {visitInfo?.visitSubject.split("/")[0]}
            </div>
          </div>
        )}
      </div>

      <div
        id="webchat"
        role="main"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0, // Ensure it stays in the background
        }}
      ></div>
    </div>
  );
};

export default BotChatComponent;
