/**
   * Use this Enumeration to maintain Error Codes across System. This will help in log analytics
   */
export enum LogSeverityLevel {
  Verbose = 0,
  Debug = 1,
  Error = 2
}

/**
 * Use this Enumeration to maintain Error Codes across System. This will help in log analytics
 */
export enum ErrorCodes {

  /** Error Codes from 01 - 09 are reserved for general Errors */
  ER_01 = 'User_Login_Failure',
  ER_02 = 'Http Request Interceptor Error',
  ER_03 = 'Http Response Interceptor Error',

  /** Error Codes from 10 - 99 are reserved for general Errors */
  //   ER_010 = '',

  /** Error Codes from 100 -199 are reserved for guest pre-register or update flow Errors */
  ER_100 = 'Error_In_Guest_Detail_Fetch',
  ER_101 = 'Error_In_VehicleMasterList_Fetch',
  ER_102 = 'Error_In_Bulding_Config_Fetch',
  ER_103 = 'Error_In_Validate_QRCode',
  ER_104 = 'Error_In_Visit_Checkin',
  ER_105 = 'Error_In_Host_Detail_Fetch',
  ER_106 = 'Error_In_Visit_Detail_Fetch',

  ER_150 = 'Error_In_Guest_Detail_Update',
  ER_151 = 'Error_In_Guest_Chat_Launch',

  ER_120 = "Error_In_Decoding_JWT_Token",
  ER_121 = "Error_In_Getting_Visit_Detail_VirtualHost",
}

export enum CommonLoggingConstants {
  ChangedBy = 'ChangedBy',
  GuestEmail = 'GuestEmail',
  CorrelationId = 'X-CorrelationId',
  LogSource = 'Log_Source',
  CodeSource = 'Code_Source',
  GPWEB = 'GP_Web',
  CurrentUrl = 'Current_URL',
  ComponentName = 'Component_Name:',
  AttributeName = 'AttributeName',
  AttributeOldValue = 'Attribute_OldValue',
  AttributeNewValue = 'Attribute_NewValue',
  EventChangeAttributeName = '{0}:{1}'
}

export enum BuildingLoggingConstants {
  GuestVisitBuildingList = 'GuestVisitBuildingList'
}

export enum GuestDetailLoggingConstants {
  GuestFirstName = 'Guest_First_Name',
  GuestLastName = 'Guest_Last_Name',
  CompanyName = 'Company_Name',
  Phone = 'Phone',
  AvatarName = 'AvatarName',
  VehicleInfo = 'Vehicle_Info',
  GuestVisitList = 'Guest_Visit_List',
  VisitId = 'Visit_Id',
  IsVehicleStateVisible = 'Is_Vehicle_State_Visible',
  UpcomingVisitList = 'Upcoming_Visit_List',
  PastVisitList = 'Past-Visit_List',
  VehicleList = 'Vehicle_Master_List',
  GuestVisitUpdateDetail = 'Guest_Visit_Update_Response',
  GuestVisitDetail = 'Guest_Visit_Detail'
}

/**
    * Use this Enumeration to maintain System Events System.
    * This will help in monitoring System Health
   */
export enum SystemEventCodes {
  /** Event codes for 10 - 99 are reserved for guest update/pre-register system events */
  SEV_010 = 'ReLoad_BuildingConfig_On_GuestVisitDetail_Change',
  SEV_011 = 'Fetch_GuestVisitDetail_On_Load',
  SEV_012 = 'Reload_On_BuildingList_Change',
  SEV_013 = 'Reload_On_Upcoming_Past_VisitList_Change'
}

export enum EventCodes {
  /** Event codes for 10 - 99 are reserved for guest update/pre-register events */
  EV_010 = 'Click_on_Update_PreRegister_Button',
  EV_011 = 'Dismiss_Update_PreRegister_Modal',

  /** Event codes for 101-199 are reserved for successful saga calls */
  EV_101 = 'Fetch_Vehicle_Master_List_Success',
  EV_102 = 'Update_GuestVisit_Detail_Success',
  EV_103 = 'Fetch_Guest_Visit_Detail_Success',
  EV_104 = 'Fetch_Building_Config_Success',
  EV_105 = 'Visit_Checkin_Success',
  EV_106 = 'Guest_Portal_Launched',
  EV_107 = 'Guest_Chat_Launched',
  EV_108 = "Fetch_Visit_Detail_Success_VirtualHost",
}

export enum TargetEntity {
  BUILDING_CONFIG = 'BuildingConfig',
  GUEST = 'Guest',
  GUEST_VECHILE = 'Guest_vechile',
  VISIT_LIST = 'Visit_List'
}
