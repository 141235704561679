import React from 'react';

const ErrorComponent = ({ responseData }) => (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
        <div>
            <h1>Error</h1>
            <p>{responseData}</p>
        </div>
    </div>
);

export default ErrorComponent;
