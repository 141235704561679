const styles = {
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    backgroundColor: "white",
    color: "black",
    //   padding: "10px 0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
  },

  header: {
    padding: "20px",
    backgroundColor: "#ECF0F1",
    borderBottom: "1px solid #BDC3C7",
  },
  hostName: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#2C3E50",
  },

  meetingTime: {
    fontSize: "16px",
    color: "black",
    marginTop: "5px",
  },
  icon: {
    marginRight: "5px",
  },
  meetingSubject: {
    fontSize: "16px",
    fontStyle: "italic",
    color: "#34495E",
    marginTop: "5px",
  },
} as const;

export default styles;
